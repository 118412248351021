// GENERATED CODE - DO NOT MODIFY BY HAND

export enum Tileset {
  Water = 8,
  Blank = 9,
  Flower1 = 10,
  Flower2 = 11,
  Grass1 = 12,
  Grass2 = 13,
  Grass3 = 14,
  LongGrassBackground = 58,
  LavaGround = 65,
  LavaMountain = 80,
  Forest = 81,
  LavaForest = 86,
  Mountain = 87,
}
export enum TileAnimationKey {
  LongGrassBackground = "LongGrassBackground",
}
export const TileAnimations: { [key in TileAnimationKey]: number[] } = {
  [TileAnimationKey.LongGrassBackground]: [58, 59, 60, 61, 62, 63, 74, 75],
};
export enum WangSetKey {
  StoneWall = "StoneWall",
  Water = "Water",
  Lava = "Lava",
  RockWall = "RockWall",
}
export const WangSets: { [key in WangSetKey]: { [key: number]: number } } = {
  [WangSetKey.StoneWall]: {
    1: 17,
    4: 18,
    5: 19,
    16: 20,
    17: 21,
    20: 22,
    21: 23,
    64: 24,
    65: 25,
    68: 26,
    69: 27,
    80: 28,
    81: 29,
    84: 30,
    85: 31,
  },
  [WangSetKey.Water]: {
    1: 33,
    4: 34,
    5: 35,
    16: 36,
    17: 37,
    20: 38,
    21: 39,
    64: 40,
    65: 41,
    68: 42,
    69: 43,
    80: 44,
    81: 45,
    84: 46,
    85: 47,
  },
  [WangSetKey.Lava]: {
    64: 48,
    5: 49,
    65: 50,
    80: 51,
    20: 52,
    16: 53,
    4: 54,
    17: 55,
    1: 56,
    68: 57,
  },
  [WangSetKey.RockWall]: {
    80: 66,
    20: 67,
    4: 68,
    16: 69,
    1: 70,
    64: 71,
    65: 82,
    5: 83,
    68: 84,
    17: 85,
  },
};
