export function Discord() {
  return (
    <svg className="fill-black" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="discord">
        <path
          id="Vector"
          d="M19.6239 4.40924C18.2217 3.75698 16.7181 3.27642 15.1459 3.00119C15.1173 2.99587 15.0887 3.00915 15.0739 3.0357C14.8805 3.38439 14.6663 3.83928 14.5163 4.19682C12.8254 3.94018 11.1431 3.94018 9.48679 4.19682C9.33676 3.83133 9.11478 3.38439 8.92053 3.0357C8.90578 3.01003 8.87718 2.99676 8.84855 3.00119C7.27725 3.27554 5.7736 3.7561 4.37052 4.40924C4.35838 4.41455 4.34797 4.4234 4.34106 4.4349C1.48894 8.75464 0.707629 12.9682 1.09092 17.1295C1.09265 17.1499 1.10392 17.1693 1.11953 17.1817C3.00127 18.5827 4.82406 19.4332 6.61301 19.9969C6.64164 20.0058 6.67197 19.9952 6.69019 19.9713C7.11337 19.3854 7.49059 18.7677 7.81402 18.1181C7.83311 18.08 7.81489 18.0349 7.77588 18.0198C7.17754 17.7897 6.6078 17.5092 6.05975 17.1906C6.0164 17.1649 6.01293 17.1021 6.05281 17.072C6.16814 16.9844 6.2835 16.8932 6.39363 16.8012C6.41355 16.7843 6.44131 16.7808 6.46474 16.7914C10.0652 18.4579 13.9631 18.4579 17.521 16.7914C17.5445 16.7799 17.5722 16.7835 17.593 16.8003C17.7032 16.8923 17.8185 16.9844 17.9347 17.072C17.9746 17.1021 17.972 17.1649 17.9286 17.1906C17.3806 17.5154 16.8108 17.7897 16.2116 18.0189C16.1726 18.034 16.1553 18.08 16.1744 18.1181C16.5047 18.7668 16.882 19.3845 17.2973 19.9704C17.3147 19.9952 17.3459 20.0058 17.3745 19.9969C19.1721 19.4332 20.9949 18.5827 22.8766 17.1817C22.8931 17.1693 22.9035 17.1508 22.9053 17.1304C23.364 12.3195 22.1369 8.14045 19.6525 4.43578C19.6465 4.4234 19.6361 4.41455 19.6239 4.40924ZM8.35169 14.5957C7.26771 14.5957 6.37454 13.5868 6.37454 12.3478C6.37454 11.1088 7.25039 10.0999 8.35169 10.0999C9.46163 10.0999 10.3462 11.1176 10.3288 12.3478C10.3288 13.5868 9.45296 14.5957 8.35169 14.5957ZM15.6619 14.5957C14.5779 14.5957 13.6847 13.5868 13.6847 12.3478C13.6847 11.1088 14.5606 10.0999 15.6619 10.0999C16.7718 10.0999 17.6563 11.1176 17.639 12.3478C17.639 13.5868 16.7718 14.5957 15.6619 14.5957Z"
        />
      </g>
    </svg>
  );
}
